import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import PostLink from "../components/post-link";
import SEO from "../components/seo";

export default function BlogList(props: {
  pageContext: {
    numPages: number;
    currentPage: number;
  };
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          fileAbsolutePath: string;
          excerpt: string;
          frontmatter: {
            title: string;
            slug: string;
            date: string;
            categories: string[];
          };
        };
      }[];
    };
  };
}) {
  const {
    pageContext: { numPages, currentPage },
    data: {
      allMarkdownRemark: { edges: posts },
    },
  } = props;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? "/posts" : `/posts/${currentPage - 1}`;
  const nextPage = `/posts/${currentPage + 1}`;
  const title = isFirst
    ? "Posts"
    : `Posts - page ${currentPage} of ${numPages}`;
  const currentUrl = isFirst ? "/posts" : `/posts/${currentPage + 1}`;

  return (
    <Layout>
      <SEO
        post={{
          title: title,
          path: "/posts",
        }}
      />
      <div className="col col-12 col-xl-8 mx-auto">
        {posts.map(({ node }) => {
          return <PostLink key={node.frontmatter.slug} {...node} />;
        })}
        <div className="d-flex justify-content-between">
          {!isFirst && (
            <Link to={prevPage} rel="prev">
              ← Previous page
            </Link>
          )}
          {Array.from({ length: numPages }, (_, i) => (
            <Link
              key={`pagination-number${i + 1}`}
              to={`/posts${i === 0 ? "" : "/" + (i + 1)}`}
              className={`p-2 rounded ${
                i + 1 === currentPage ? "bg-primary text-white" : ""
              }`}
            >
              {i + 1}
            </Link>
          ))}
          {!isLast && (
            <Link to={nextPage} rel="next">
              Next page →
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fileAbsolutePath
          excerpt(pruneLength: 300)
          frontmatter {
            title
            slug
            date(formatString: "YYYY-MM-DD")
            categories
          }
        }
      }
    }
  }
`;
